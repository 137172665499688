import React, { createContext, useState, useContext, useCallback } from "react";
import Replicate from "replicate";

// Create LLMContext
export const LLMContext = createContext();

// Custom hook to access LLM context
export const useLLM = () => useContext(LLMContext);

// Helper function to handle API calls
const replicateRequest = async (modelId, inputParams) => {
  const replicate = new Replicate({
    auth: process.env.REACT_APP_REPLICATE_API_TOKEN, // Ensure .env is configured properly
  });

  try {
    const output = await replicate.run(modelId, { input: inputParams });
    return output;
  } catch (error) {
    throw new Error(
      `Failed to fetch data from Replicate API: ${error.message}`,
    );
  }
};

// LLMProvider Component
export const LLMProvider = ({ children }) => {
  const [feedback, setFeedback] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Model IDs (could be moved to a config file)
  const ANALYZE_MODEL_ID = "your-analysis-model-id";
  const SUGGESTIONS_MODEL_ID = "your-suggestions-model-id";

  // Function to analyze journal entry text and provide feedback
  const analyzeEntry = useCallback(
    async (entryText) => {
      setLoading(true);
      setError(null);

      try {
        const response = await replicateRequest(ANALYZE_MODEL_ID, {
          text: entryText,
        });
        setFeedback(response.output);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [ANALYZE_MODEL_ID],
  );

  // Function to generate writing suggestions based on mood
  const generateSuggestions = useCallback(
    async (mood) => {
      setLoading(true);
      setError(null);

      try {
        const response = await replicateRequest(SUGGESTIONS_MODEL_ID, { mood });
        setSuggestions(response.output);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [SUGGESTIONS_MODEL_ID],
  );

  // Function to clear feedback and suggestions (optional, if needed)
  const clearLLMData = () => {
    setFeedback(null);
    setSuggestions([]);
    setError(null);
  };

  // Retry logic for failed API calls
  const retryRequest = async (
    requestFunction,
    args,
    retries = 3,
    delay = 1000,
  ) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await requestFunction(...args);
      } catch (error) {
        if (i === retries - 1) throw error; // If max retries are reached, throw error
        await new Promise((res) => setTimeout(res, delay)); // Wait before retrying
      }
    }
  };

  return (
    <LLMContext.Provider
      value={{
        feedback,
        suggestions,
        analyzeEntry,
        generateSuggestions,
        clearLLMData,
        loading,
        error,
        retryRequest,
      }}
    >
      {children}
    </LLMContext.Provider>
  );
};
