import dotenv from "dotenv";
import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css"; // Global styles
import "./firebase"; // Firebase setup
import Loading from "./components/Loading";
import PrivateRoute from "./components/PrivateRoute"; // Ensuring only logged-in users access protected routes
import { AuthProvider } from "./contexts/AuthContext";
import { LLMProvider } from "./contexts/LLMContext";

// Lazy-loaded Views
const Landing = lazy(() => import("./views/Landing"));
const Profile = lazy(() => import("./views/Profile"));
const Index = lazy(() => import("./views/Index"));
const Journal = lazy(() => import("./views/auth/Journal"));
const CalendarPage = lazy(() => import("./views/CalendarPage"));
const Login = lazy(() => import("./views/auth/Login"));
const Register = lazy(() => import("./views/auth/Register"));
const NotFound = lazy(() => import("./views/NotFound"));

// Load environment variables
dotenv.config();

// Create root element and render the App component
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <LLMProvider>
        <Router>
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* Catch all undefined routes */}
              <Route path="*" element={<NotFound />} />
              <Route path="/landing" element={<Landing />} />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />{" "}
              {/* Profile should be private */}
              <Route
                path="/journal"
                element={
                  <PrivateRoute>
                    <Journal />
                  </PrivateRoute>
                }
              />
              <Route
                path="/calendar"
                element={
                  <PrivateRoute>
                    <CalendarPage />
                  </PrivateRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Register />} />
              <Route path="/" element={<Index />} /> {/* Default path */}
            </Routes>
          </Suspense>
        </Router>
      </LLMProvider>
    </AuthProvider>
  </React.StrictMode>,
);
